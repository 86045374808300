import { render, staticRenderFns } from "./error.vue?vue&type=template&id=65a156e9&scoped=true"
import script from "./error.vue?vue&type=script&lang=ts"
export * from "./error.vue?vue&type=script&lang=ts"
import style0 from "./error.vue?vue&type=style&index=0&id=65a156e9&prod&scoped=true&lang=css"
import style1 from "./error.vue?vue&type=style&index=1&id=65a156e9&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a156e9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Error404: require('/app/apps/flatpages/error404.vue').default,Error500: require('/app/components/errors/error500.vue').default,ErrorGeneral: require('/app/components/errors/errorGeneral.vue').default})
