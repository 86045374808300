
import {
  useCurrencyStore,
  useFacetsStore,
  usePinia,
  useSearchWizardStore,
} from '#imports'
import { Component, Mixins, Watch } from '~/utility/pu-class-decorator'
import { nextBookingContainer } from '~/apps/user-bookings/next-booking/inversify.config'
import { NextBookingMachineInterpreter } from '~/apps/user-bookings/next-booking/nextBookingMachine'
import type { NextBooking } from '~/apps/user-bookings/next-booking/types'
import {
  PendingReviewMachineInterpreter,
  getPendingReviewsMachine,
} from '~/apps/user-reviews/pending-review/pendingReviewMachine'
import type { PendingReview } from '~/apps/user-reviews/pending-review/schemas'
import AuthMixin from '~/mixins/AuthMixin'
import ErrorCapture from '~/mixins/ErrorCaptured'
import { isDayPast } from '~/utility/date/relative'

@Component({
  mixins: [ErrorCapture],
  setup() {
    const searchWizardStore = useSearchWizardStore(usePinia())
    // setup facet store so facets available to all components
    const facetsStore = useFacetsStore()
    // setup to fetch currencies ASAP
    const currencyStore = useCurrencyStore()
    return {
      currencyStore,
      facetsStore,
      searchWizardStore,
    }
  },
})
export default class UserAccountDesktop extends Mixins(AuthMixin) {
  nextBooking: NextBooking | null = null
  pendingReviews: PendingReview[] = []

  async mounted() {
    this.fetchNextBooking()
    this.startPendingReviewsMachine()
    return await Promise.resolve()
  }

  @Watch('isLoggedIn')
  private fetchNextBooking() {
    if (!this.isLoggedIn) {
      this.nextBooking = null
      return
    }
    const machine: NextBookingMachineInterpreter =
      nextBookingContainer.get('NextBookingMachine')
    machine
      .onChange(({ booking }) => {
        if (booking && !this.nextBooking) {
          this.nextBooking = booking
        }
      })
      .start()
  }

  @Watch('isLoggedIn')
  fetchPendingReviews() {
    this.pendingReviewsMachine?.send('FETCH_PENDING_REVIEW')
  }

  pendingReviewsMachine: PendingReviewMachineInterpreter | null = null

  private startPendingReviewsMachine() {
    this.pendingReviewsMachine = getPendingReviewsMachine(
      this.$route.params.lang || 'en-gb',
    )
      .onTransition(({ context }) => {
        this.pendingReviews = context.reviews
      })
      .start()
  }

  get isNextBookingPast() {
    return this.nextBooking ? isDayPast(this.nextBooking.arrive) : false
  }
}
