import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2518b737&scoped=true"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"
import style0 from "./default.vue?vue&type=style&index=0&id=2518b737&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2518b737",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeaderDesktop: require('/app/components/layouts/app-header-desktop.vue').default,AppHeader: require('/app/components/layouts/app-header.vue').default,RecentCampsites: require('/app/components/layouts/components/RecentCampsites.vue').default,AppFooterDesktop: require('/app/components/layouts/AppFooterDesktop.vue').default,AppFooter: require('/app/components/layouts/AppFooter.vue').default,AppMenu: require('/app/components/layouts/components/app-menu/AppMenu.vue').default})
