import { Context, Middleware } from '@nuxt/types'

declare module 'vue/types/options' {
  // @ts-ignore
  export interface ComponentOptions<V extends Vue> {
    httpHeaders?(context: Context): Record<string, string>
  }
}

const NUXT_CUSTOM_HEADERS_FUNCTION = 'httpHeaders'

const httpHeaders: Middleware = (context) => {
  if (process.server) {
    const headers: Record<string, string> = ([] as any[]).concat
      .apply(
        [],
        context.route.matched.map(({ components }) =>
          Object.values(components),
        ),
      )
      .reduce((headers2, component) => {
        if (
          component.options &&
          component.options[NUXT_CUSTOM_HEADERS_FUNCTION]
        ) {
          Object.assign(
            headers2,
            component.options[NUXT_CUSTOM_HEADERS_FUNCTION](context),
          )
        }
        return headers2
      }, {})
    if (context.res) {
      Object.keys(headers).forEach((header) =>
        context.res.setHeader(header, headers[header]),
      )
    }
  }
}

export default httpHeaders
