import { render, staticRenderFns } from "./homepage-layout.vue?vue&type=template&id=4cae93aa"
import script from "./homepage-layout.vue?vue&type=script&lang=ts"
export * from "./homepage-layout.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeaderDesktop: require('/app/components/layouts/app-header-desktop.vue').default,AppHeader: require('/app/components/layouts/app-header.vue').default,SearchWizardHomepageBanner: require('/app/apps/search-wizard/homepage-banner/components/SearchWizardHomepageBanner.vue').default})
