
import { NuxtError } from '@nuxt/types'
import ErrorCaptured from '~/mixins/ErrorCaptured'
import { Component, Mixins, Prop, Vue } from '~/utility/pu-class-decorator'

@Component<ErrorLayout>({
  layout: 'default',
  head: function () {
    return this.head()
  },
})
export default class ErrorLayout extends Mixins(Vue, ErrorCaptured) {
  head() {
    return {
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex',
        },
      ],
    }
  }

  @Prop({ type: Object, required: true }) readonly error!: NuxtError & {
    httpHeaders?: Record<string, string>
  }

  created() {
    const httpHeaders = this.error.httpHeaders
    if (this.error && typeof httpHeaders !== 'undefined' && process.server) {
      Object.keys(httpHeaders).forEach((key) => {
        httpHeaders[key]
          ? this.$nuxt.context.res.setHeader(key, httpHeaders[key])
          : this.$nuxt.context.res.removeHeader(key)
      })
    }
  }

  mounted() {
    if (Number(this.error.statusCode) == 404) {
      this.sendBreadcrumb(this.error.message || '')
    } else {
      this.sendErrorAndOpenFeedbackModal()
    }
    this.togglePageWrapperClass(true)
  }

  beforeDestroy() {
    this.togglePageWrapperClass(false)
  }

  get contactUsLink() {
    return `https://www.pitchup.com/${
      this.$i18n.locale !== 'en-gb' ? this.$i18n.locale + '/' : ''
    }about/contact/`
  }

  private get errorEvent() {
    const error = new Error(this.error.message)
    error.name = `Error ${this.error.statusCode}: ${this.error.message}`
    return error
  }

  private async sendErrorAndOpenFeedbackModal() {
    if (typeof this.$sentryReady === 'undefined') {
      // no sentry
      // eslint-disable-next-line no-console
      console.error(this.error)
      return
    }
    const sentryReadyInstance = await this.$sentryReady()
    const eventId = sentryReadyInstance.captureException(this.errorEvent)
    if (eventId) {
      // @ts-ignore
      sentryReadyInstance.showReportDialog({ eventId })
    }
  }

  private async sendBreadcrumb(message: string) {
    if (typeof this.$sentryReady === 'undefined') {
      // no sentry
      // eslint-disable-next-line no-console
      console.error(this.error)
      return
    }
    const sentryReadyInstance = await this.$sentryReady()
    sentryReadyInstance.addBreadcrumb({
      category: '404',
      message,
      level: 'error',
    })
  }

  private togglePageWrapperClass(hasWrapper: boolean) {
    const pageWrapperEl = document.querySelector('#page-wrapper')
    if (!pageWrapperEl) return

    if (hasWrapper) pageWrapperEl.classList.add('error-page-wrapper')
    else pageWrapperEl.classList.remove('error-page-wrapper')
  }

  get isDev() {
    return process.env.NODE_ENV !== 'production'
  }
}
