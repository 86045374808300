
import {
  useCurrencyStore,
  useFacetsStore,
  usePinia,
  useSearchWizardStore,
} from '#imports'
import { mixins } from 'vue-class-component'
import LazyHydrate from 'vue-lazy-hydration'
import { MetaInfo } from 'vue-meta'
import { AppMenuMixin } from '~/apps/app-menu/mixin'
import ErrorCapture from '~/mixins/ErrorCaptured'
import { getCookie } from '~/utility/cookies'
import { getUserCountry } from '~/utility/geo/geo-location'
import { Component } from '~/utility/pu-class-decorator'
import { isSearchUrlRoute } from '../apps/search/isSearchUrlComponent'

@Component<Default>({
  components: {
    'portal-target': () => {
      if (process.client) {
        return import(
          /* webpackChunkName: "vue-portal", webpackMode: "lazy" */ 'portal-vue'
        ).then(({ PortalTarget }) => PortalTarget)
      } else {
        return
      }
    },
    LazyHydrate,
  },
  head: function () {
    return this.head()
  },
  setup() {
    const searchWizardStore = useSearchWizardStore(usePinia())
    // setup facet store so facets available to all components
    const facetsStore = useFacetsStore()
    // setup to fetch currencies ASAP
    const currencyStore = useCurrencyStore()
    return {
      currencyStore,
      facetsStore,
      searchWizardStore,
    }
  },
})
export default class Default extends mixins(AppMenuMixin, ErrorCapture) {
  searchWizardStore: ReturnType<typeof useSearchWizardStore>
  loadHeader = false
  loadFooter = false

  head(): MetaInfo {
    if (!this.$nuxtI18nHead) return {}
    const head = this.$nuxtI18nHead({ addSeoAttributes: true })
    if (isSearchUrlRoute(this.$route) && this.searchWizardStore.totalCounts === 0) {
      return head
    }
    const lang = this.$route.params.lang || 'en-gb'
    const basePath = lang
      ? this.$route.path.replace(`/${lang}/`, '/')
      : this.$route.path
    const hreflangs = new Set()

    this.$i18n.locales.forEach((locale) => {
      const addHreflang = (hreflang, href) => {
        if (!hreflangs.has(hreflang)) {
          hreflangs.add(hreflang)
          head.link.push({ rel: 'alternate', hreflang, href })
        }
      }

      if (locale.code === 'en-gb') {
        ['en-gb', 'en', 'x-default'].forEach((l) => {
          addHreflang(l, `https://${this.$config.public.envUrl}${basePath}`)
        })
      } else {
        addHreflang(
          locale.code,
          `https://${this.$config.public.envUrl}/${locale.code}${basePath}`,
        )
        if (locale.alternateHreflang) {
          addHreflang(
            locale.alternateHreflang,
            `https://${this.$config.public.envUrl}/${locale.code}${basePath}`,
          )
        }
      }
    })

    return head
  }

  async mounted() {
    await this.setShowLanguageSwitcher()
  }

  get isErrored() {
    return this.$nuxt.context._errored
  }

  get userLocaleIso() {
    if (process.client) {
      return navigator.language
    }
    return null
  }

  get currentLocale() {
    return this.$i18n.locale
  }

  get totalCounts() {
    return this.searchWizardStore.totalCounts
  }

  showLanguageSwitcher = false

  async setShowLanguageSwitcher() {
    if (process.server) {
      return
    }
    if (!this.userLocaleIso || getCookie('hide_language_panel')) return
    const selectedLocale = localStorage.getItem('pitchup-language-v2')
    if (selectedLocale) return
    const userLocale = this.$i18n.locales.find(
      (l) => l.iso === this.userLocaleIso,
    )
    if (!userLocale) {
      return
    }
    // lots of en-gb browsers configured to be en-us or they are crawlers/testtools
    const isUSonGB =
      userLocale.code === 'en-us' && this.currentLocale === 'en-gb'
    if (isUSonGB) {
      const geoLocale = await getUserCountry()
      if (geoLocale !== 'US') {
        // if they aren't the US, then we can assume they are GB
        return
      }
    }

    if (userLocale.code !== this.currentLocale) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve()
        }, 3000)
      })
      this.showLanguageSwitcher = true
    }
  }
}
